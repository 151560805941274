<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Ofertas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Ofertas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <h5>Ofertas</h5>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="alert alert-warning alert-dismissible fade show m-2"
                      role="alert"
                    >
                      <div>
                        Señor usuario recuerde que solo puede ofertar hasta el
                        <strong>{{ planeacion.fecha_final_oferta }}</strong> sin
                        que genere afectaciones en su calificacion
                      </div>
                      <div>
                        Los volúmenes visualizados corresponden al total
                        requerido en la operación mensual.
                      </div>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th style="width: 30%">
                            Empresa
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="listasForms.slct_empresas"
                              placeholder="Empresas"
                              label="razon_social"
                              class="form-control form-control-sm p-0"
                              :options="empresas_list"
                              :filterable="false"
                              @search="buscarEmpresas"
                              @input="getIndex()"
                            ></v-select>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(empresa, index_empresa) in empresas.data"
                          :key="index_empresa"
                        >
                          <td class="p-1">
                            <div class="row">
                              <div class="col-md-8">
                                <label class="p-2">
                                  {{ empresa.razon_social }}
                                </label>
                              </div>
                              <div class="col-md-4 text-right">
                                <div class="btn-group float-right">
                                  <button
                                    type="button"
                                    class="btn btn-sm bg-navy"
                                    data-toggle="modal"
                                    data-target="#Modal_calificacion"
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.ofertas.verCalificacion'
                                      )
                                    "
                                    @click="getCalificacion(empresa)"
                                  >
                                    <i class="fas fa-eye"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <table
                              class="table table-bordered table-striped table-hover table-sm"
                            >
                              <thead class="thead-dark">
                                <tr>
                                  <th class="text-center">Origen</th>
                                  <th class="text-center">
                                    Volumen operación mensual
                                  </th>
                                  <th class="text-center">Prom. cargues mes</th>
                                  <th class="text-center">Prom. cargues día</th>
                                  <th class="text-center" style="width: 120px">
                                    Oferta día
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(planeacion,
                                  index_planeacion) in PlaneacionVolumen"
                                  :key="index_planeacion"
                                >
                                  <td>{{ planeacion.origen }}</td>
                                  <td class="text-right">
                                    {{ planeacion.volumen_total.toFixed(2) }}
                                  </td>
                                  <td class="text-right">
                                    {{
                                      planeacion.cargues_mes_total.toFixed(2)
                                    }}
                                  </td>
                                  <td class="text-right">
                                    {{
                                      planeacion.cargues_dia_total.toFixed(2)
                                    }}
                                  </td>
                                  <td
                                    v-if="
                                      form[index_empresa][index_planeacion].id
                                    "
                                    class="text-center"
                                  >
                                    {{
                                      form[index_empresa][index_planeacion]
                                        .cargues_dia_ofertado
                                    }}
                                  </td>
                                  <td v-else>
                                    <input
                                      type="number"
                                      min="0"
                                      :max="
                                        Math.ceil(planeacion.cargues_dia_total)
                                      "
                                      class="form-control form-control-sm"
                                      id="cargues_dia_ofertado"
                                      v-model="
                                        form[index_empresa][index_planeacion]
                                          .cargues_dia_ofertado
                                      "
                                      :class="
                                        form[index_empresa][index_planeacion]
                                          .cargues_dia_ofertado &&
                                        form[index_empresa][index_planeacion]
                                          .cargues_dia_ofertado != ''
                                          ? 'is-valid'
                                          : 'is-invalid'
                                      "
                                      @blur="
                                        cambiarEstado(
                                          index_empresa,
                                          index_planeacion,
                                          planeacion.cargues_dia_total
                                        )
                                      "
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-left">
                  <p>
                    Mostrando del
                    <b>{{ empresas.from }}</b> al <b>{{ empresas.to }}</b> de un
                    total: <b>{{ empresas.total }}</b> Registros
                  </p>
                </div>
                <pagination
                  :data="empresas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i>
                    <br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- Modal cambio de volúmenes -->
      <div class="modal fade" id="Modal_calificacion">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">
                Calificación - {{ modal_title }}
              </h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Cuerpo del modal -->
            <div class="modal-body" v-if="modalData">
              <!-- Calificacion General  -->
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="description-block">
                        <h2>{{ modalData.pivot.resultado_general }}%</h2>
                        <span class="description-text"
                          >Calificación General</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Calificaciones detalladas -->
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="row">
                    <div
                      class="col-sm-6"
                      v-for="(calificacion,
                      index_calificacion) in modalData.porcentajes_items_evaluaciones"
                      :key="index_calificacion"
                    >
                      <div class="description-block">
                        <h4>
                          {{ calificacion.pivot.resultado_calificacion }}%
                        </h4>
                        <span class="description-text">
                          {{ calificacion.nombre }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-body" v-else>
              <div class="row">
                <div class="col-md-12">
                  <div class="alert alert-warning alert-dismissible">
                    <h5>
                      <i class="icon fas fa-exclamation-triangle"></i> Alert!
                    </h5>
                    <div>
                      No hay calificaciones disponibles para mostrar de la
                      empresa
                      <strong>{{ modal_title }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
// import EnvioCorreo from "./EnvioCorreo";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";

export default {
  name: "OfertaIndex",
  components: {
    pagination,
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      planeacion_id: null,
      empresas: {},
      modal_title: null,
      modalData: null,
      PlaneacionVolumen: {},
      filtros: {
        empresa_id: null,
      },
      listasForms: {
        slct_empresas: [],
      },
      empresas_list: [],
      form: {},
      planeacion: {},
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.planeacion_id = this.$route.params.planeacion_id;
      // Filtro para empresas
      this.filtros.empresa_id = null;
      if (this.listasForms.slct_empresas) {
        this.filtros.empresa_id = this.listasForms.slct_empresas.id;
      }

      axios
        .get(
          "/api/hidrocarburos/ofertas/show?page=" +
            page +
            "&planeacion_id=" +
            this.planeacion_id,
          {
            params: this.filtros,
          }
        )
        .then(async (response) => {
          this.empresas = response.data.empresas;
          this.planeacion = response.data.Planeacion;
          await this.getPlaneaciones(response.data.PlaneacionVolumen);
          this.cargando = false;
        });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        me.empresas_list = [];
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.empresas_list = respuesta;
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async getPlaneaciones(planeacion) {
      let array = [];
      let flag_origen = [];
      planeacion.forEach((element) => {
        let posicion = flag_origen.indexOf(element.origen_id);
        if (posicion != -1) {
          array[posicion].volumen_total += element.volumen;
          array[posicion].cargues_mes_total =
            array[posicion].volumen_total / 200;
          array[posicion].cargues_dia_total =
            array[posicion].volumen_total / 200 / 30;
        } else {
          flag_origen.push(element.origen_id);
          let origen = ``;
          if (element.origen) {
            origen = element.origen.nombre;
          }
          let cargues_mes_total = element.volumen / 200;
          let cargues_dia_total = element.volumen / 200 / 30;
          array.push({
            id: element.planeacion_id,
            origen,
            origen_id: element.origen_id,
            volumen_total: element.volumen,
            cargues_mes_total: cargues_mes_total,
            cargues_dia_total: cargues_dia_total,
            ofertas: element.planeacion.ofertas_planeaciones_hidrocarburos,
            planeacion,
          });
        }
      });
      this.PlaneacionVolumen = array;
      await this.getForm();
    },

    getCalificacion(empresa) {
      let me = this;
      me.modalData = null;
      this.modal_title = empresa.razon_social;

      const empresa_id = empresa.id;
      const params = {
        empresa_id,
        planeacion_id: this.planeacion_id,
        fecha_inicial: this.planeacion.fecha_inicial,
      };
      var url = "/api/hidrocarburos/ofertas/getCalificacion";
      axios
        .get(url, { params })
        .then(function(response) {
          if (response.data.calificacion) {
            me.modalData = response.data.calificacion[0].empresas[0];
          } else {
            me.modalData = null;
          }
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getForm() {
      let array = [];
      this.empresas.data.forEach((empresa) => {
        let array_planeacion = [];
        this.PlaneacionVolumen.forEach((planeacion) => {
          let id = null;
          let cargues_dia_ofertado = null;
          if (planeacion.ofertas.length > 0) {
            planeacion.ofertas.forEach((oferta) => {
              if (
                empresa.id == oferta.empresa_id &&
                planeacion.origen_id == oferta.origen_id
              ) {
                id = oferta.id;
                cargues_dia_ofertado = oferta.cargues_dia_ofertado;
              }
            });
          }
          array_planeacion.push({
            id,
            planeacion_id: planeacion.id,
            empresa_id: empresa.id,
            origen_id: planeacion.origen_id,
            volumen_total: planeacion.volumen_total,
            cargues_mes_total: planeacion.cargues_mes_total,
            cargues_dia_total: planeacion.cargues_dia_total,
            cargues_dia_ofertado,
          });
        });
        array.push(array_planeacion);
      });
      this.form = array;
    },

    back() {
      return this.$router.replace("/Hidrocarburos/Ofertas");
    },

    cambiarEstado(index_empresa, index_planeacion, cargues_dia_total) {
      // let cargue = Math.ceil(cargues_dia_total);
      // if (
      //   this.form[index_empresa][index_planeacion].cargues_dia_ofertado <=
      //     cargue &&
      //   this.form[index_empresa][index_planeacion].cargues_dia_ofertado != null
      // ) {
      // Se pregunta si está seguro de realizar esta operacion
      this.$swal({
        title: `Está seguro de ofertar ${this.form[index_empresa][index_planeacion].cargues_dia_ofertado} vehículos?`,
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Ofertar!",
      }).then((result) => {
        if (result.value) {
          this.save(this.form[index_empresa][index_planeacion]);
        } else {
          this.form[index_empresa][
            index_planeacion
          ].cargues_dia_ofertado = null;
        }
      });
      /***
       * Se elimina la restricción de no poder ofertar más vehículos de los que
       *  tiemne permitido pos solicitud del cliente
       * */
      // } else {
      //   this.form[index_empresa][index_planeacion].cargues_dia_ofertado = null;
      //   this.$swal({
      //     icon: "error",
      //     title: `No es posible ofertar esta cantidad, puede ofertar máximo ${cargue} vehículos para esta planeación.`,
      //     toast: true,
      //     position: "top-end",
      //     showConfirmButton: false,
      //     timer: 5000,
      //     timerProgressBar: true,
      //   });
      // }
    },

    save(form) {
      axios({
        method: "PUT",
        url: "/api/hidrocarburos/ofertas",
        data: form,
      })
        .then((response) => {
          if (response.data.msg == "Se genero un descuento del 10%") {
            this.$swal(
              response.data.msg,
              "Se ofertaron los datos, pero se genero un descuento del 10% a su calificacion!",
              "warning"
            );
            this.getIndex();
          } else if (response.data.msg == "OK") {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "La oferta se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.$swal(response.data.msg, "Se encontro el error!", "error");
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
